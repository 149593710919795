import { TranslationMessages } from 'react-admin';
import russianMessages from 'ra-language-russian';

const customRussianMessages: TranslationMessages = {
    ...russianMessages,
    ra: {
        ...russianMessages.ra,
        configurable: {
            ...russianMessages.ra?.configurable,
            customize: 'Настроить',
        },
        notification: {
            ...russianMessages.ra?.notification,
            data_provider_error: 'Возникла ошибка, проверьте консоль',
        },
    },
    login: {
        welcome_message: 'Панель администрирования',
    },
    site: {
        name: 'Fastrans',
    },
    pos: {
        search: 'Поиск',
        configuration: 'Конфигурация',
        language: 'Язык',
        theme: {
            name: 'Тема',
            light: 'Светлая',
            dark: 'Тёмная',
        },
        dashboard: {
            welcome: {
                title: 'Добро пожаловать в панель администрирования Fastrans.',
                subtitle:
                    'Это раздел для администрирования сайта Fastrans. Используйте боковое меню, чтобы получить доступ к нужному разделу.',
            },
        },
        menu: {
            content: 'Контент',
            settings: 'Настройки',
            cities: 'Населённые пункты',
            tools: 'Утилиты',
            blocks: 'Блоки',
            seo: 'SEO',
            logix: 'Logix',
        },
    },
    resources: {
        admins: {
            name: 'Администратор |||| Администраторы',
            fields: {
                id: 'ИД',
                username: 'Логин',
                email: 'Email',
                first_name: 'Имя',
                last_name: 'Фамилия',
                date_joined: 'Создан',
                last_login: 'Последний вход',
                is_active: 'Активен',
                is_staff: 'Персонал',
                is_superuser: 'Суперпользователь',
                password: 'Пароль',
                confirm_password: 'Подтверждение пароля',
            },
            filters: {
                id: 'ИД',
                username: 'Логин',
                email: 'Email',
                first_name: 'Имя',
                last_name: 'Фамилия',
                date_joined: 'Создан',
                last_login: 'Последний вход',
                is_active: 'Активен',
                is_staff: 'Персонал',
                is_superuser: 'Суперпользователь',
                password: 'Пароль',
                confirm_password: 'Подтверждение пароля',
            },
            fieldGroups: {
                identity: 'Персональные данные',
                password: 'Пароль',
                change_password: 'Смена пароля',
                history: 'История',
            },
            page: {
                delete: 'Удалить администратора',
            },
            errors: {
                password_mismatch:
                    'Подтверждение пароля не совпадает с паролем.',
            },
        },
        cities: {
            name: 'ГСТ',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Наименование',
                domain: 'Домен',
                active: 'Активен',
                email: 'Email',
                min_price: 'Мин. стоимость въезда',
                delivery_time: 'Время доставки',
                paid_entry: 'Платный въезд',
                terminal_key: 'ИД терминала',
                terminal_name: 'Наименование терминала',
                wordform_vn: 'Наименование (вин. падеж)',
                wordform_rod: 'Наименование (род. падеж)',
                wordform_dat: 'Наименование (дат. падеж)',
                wordform_pr: 'Наименование (пр. падеж)',
                additional: 'Доп. данные',
                timezone: 'Часовая зона',
                latitude: 'Широта',
                longitude: 'Долгота',
                default_zoom: 'Масштаб по умолчанию',
                generate_to: 'Генерировать страницы to',
                generate_from: 'Генерировать страницы from',
                generate_any: 'Генерировать страницы со всеми городами',
                schedule: 'Расписание',
                schedule_h_day: 'День',
                schedule_h_value: 'Рабочие часы',
                schedule_monday: 'Понедельник',
                schedule_tuesday: 'Вторник',
                schedule_wednesday: 'Среда',
                schedule_thursday: 'Четверг',
                schedule_friday: 'Пятница',
                schedule_saturday: 'Суббота',
                schedule_sunday: 'Воскресенье',
                schedule_offdays: 'Нерабочие дни',
                free_delivery: 'Доставка включена в МГ тариф',
                border_town: 'Краевой город',
                geo_id: 'ИД НП (Logix)',
                sanctions_city_id: 'ГсТ (санкционный)',
                cities_item: 'НП',
                'cities_item.title': 'НП',
            },
            filters: {
                id: 'ИД',
                title: 'Наименование',
                active: 'Активен',
            },
            fieldGroups: {},
            helpers: {
                active:
                    'Если ГсТ создаётся как дублирующий ГсТ под санкциями, он должен быть неактивным',
                sanctions_city_id:
                    'Для указания ГсТ с наличием санкционных тарифов необходимо создать дублирующий неактивный ГсТ и указать его в нижеследующем поле.',
                parent_city_id:
                    'Указатель на ГсТ с обслуживающим терминалом. Необходимо заполнить, если ГсТ без терминала.',
                schedule:
                    "Формат рабочих часов: указываются диапазоны времени, в которое офис работает. Диапазоны разделяются запятыми (с пробелом или без). Диапазон записывается как пара (разделяется символом '-' без пробела) значения часы/минуты. Значение часы минуты указывается в формате ЧЧ:ММ. Формат времени: 24-часовой с ведущими нулями, время указывается в московском часовом поясе. Пример значения: 09:00-13:00,13:30-18:00 Формат нерабочих дней: указываются дни, в которые офис не будет работать. Дни разделяются запятыми (с пробелом или без). Формат указания дней: ДД-ММ-ГГГГ. Пример значения: 01-01-2020,02-01-2020",
                delete: 'Удалить',
            },
            errors: {},
        },
        pages: {
            name: 'Страница |||| Страницы',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Заголовок',
                active: 'Активна',
                slug: 'ЧПУ',
                annotation: 'Аннотация',
                content: 'Контент',
                additional_content_1: 'Дополнительный контент 1',
                additional_content_2: 'Дополнительный контент 2',
                seo_h1: 'H1',
                seo_keywords: 'Keywords',
                seo_description: 'Описание',
                seo_no_index: 'NO_INDEX',
                seo_canonincal: 'Canonical',
                city: 'Город',
                city_id: 'Город',
                city_id__select: 'Город (создание страницы)',
                'city.title': 'Город',
            },
            helpers: {
                city_id:
                    'Если город не выбран, тогда настройки ниже распространяются на все города, кроме указанных в данном списке (настройка по-умолчанию). Чтобы сбросить выбор текущего города нажмите на крестик, появляющийся если навести (кликнуть) на данное поле.',
                city_id__select:
                    'Общий список доступных городов. При выборе будет создана версия страницы для этого города.',
            },
            filters: {
                id: 'ИД',
                title: 'Наименование',
                active: 'Активна',
                slug: 'ЧПУ',
                city_id: 'Город',
            },
            fieldGroups: {
                seo: 'SEO',
            },
            page: {
                delete: 'Удалить',
            },
            errors: {},
        },
        news: {
            name: 'Новость |||| Новости',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Заголовок',
                active: 'Активна',
                slug: 'ЧПУ',
                annotation: 'Аннотация',
                content: 'Контент',
                seo_h1: 'H1',
                seo_keywords: 'Keywords',
                seo_description: 'Описание',
                seo_no_index: 'NO_INDEX',
                seo_canonincal: 'Canonical',
                city: 'Город',
                city_id: 'Город',
                'city.title': 'Город',
                image: 'Изображение',
                'image.src': 'Изображение',
                content_date: 'Дата (контент)',
                active_time: 'Конечная дата отображения на главной',
                is_all_cities: 'Все города',
            },
            filters: {
                id: 'ИД',
                title: 'Наименование',
                active: 'Активна',
                slug: 'ЧПУ',
                city_id: 'Город',
            },
            fieldGroups: {
                seo: 'SEO',
            },
            page: {
                delete: 'Удалить',
            },
            errors: {},
        },
        sales: {
            name: 'Акция |||| Акции',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Заголовок',
                active: 'Активна',
                slug: 'ЧПУ',
                annotation: 'Аннотация',
                content: 'Контент',
                seo_h1: 'H1',
                seo_keywords: 'Keywords',
                seo_description: 'Описание',
                seo_no_index: 'NO_INDEX',
                seo_canonincal: 'Canonical',
                city: 'Город',
                city_id: 'Город',
                'city.title': 'Город',
                image: 'Изображение',
                'image.src': 'Изображение',
                content_date: 'Дата (контент)',
                active_time: 'Конечная дата отображения на главной',
                is_all_cities: 'Все города',
            },
            filters: {
                id: 'ИД',
                title: 'Наименование',
                active: 'Активна',
                slug: 'ЧПУ',
                city_id: 'Город',
            },
            fieldGroups: {
                seo: 'SEO',
            },
            page: {
                delete: 'Удалить',
            },
            errors: {},
        },
        articles: {
            name: 'Статья |||| Статьи',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Заголовок',
                active: 'Активна',
                slug: 'ЧПУ',
                annotation: 'Аннотация',
                content: 'Контент',
                additional_content_1: 'Дополнительный контент 1',
                additional_content_2: 'Дополнительный контент 2',
                seo_h1: 'H1',
                seo_keywords: 'Keywords',
                seo_description: 'Описание',
                seo_no_index: 'NO_INDEX',
                seo_canonincal: 'Canonical',
            },
            filters: {
                id: 'ИД',
                title: 'Наименование',
                active: 'Активна',
                slug: 'ЧПУ',
            },
            fieldGroups: {
                seo: 'SEO',
            },
            page: {
                delete: 'Удалить',
            },
            errors: {},
        },
        vacancies: {
            name: 'Вакансия |||| Вакансии',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Заголовок',
                active: 'Активна',
                slug: 'ЧПУ',
                annotation: 'Аннотация',
                content: 'Контент',
                seo_h1: 'H1',
                seo_keywords: 'Keywords',
                seo_description: 'Описание',
                seo_no_index: 'NO_INDEX',
                seo_canonincal: 'Canonical',
                city: 'Город',
                city_id: 'Город',
                'city.title': 'Город',
                image: 'Изображение',
                'image.src': 'Изображение',
                requirements: 'Требования',
                responsibility: 'Обязанности',
                cond: 'Условия',
                address: 'Адрес',
            },
            filters: {
                id: 'ИД',
                title: 'Наименование',
                active: 'Активна',
                slug: 'ЧПУ',
                city_id: 'Город',
            },
            fieldGroups: {
                seo: 'SEO',
            },
            page: {
                delete: 'Удалить',
            },
            errors: {},
        },
        city_pages: {
            name: 'Страница доставки |||| Страницы доставки',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Заголовок',
                active: 'Активна',
                slug: 'ЧПУ',
                annotation: 'Аннотация',
                content: 'Контент',
                seo_h1: 'H1',
                seo_keywords: 'Keywords',
                seo_description: 'Описание',
                seo_no_index: 'NO_INDEX',
                seo_canonincal: 'Canonical',
                links_title_city_from: 'Текст ссылок (для города отправления)',
                links_title_city_to: 'Текст ссылок (для города назначения)',
                links_title_intercity_from:
                    'Текст ссылок (междугородние прямые)',
                links_title_intercity_to:
                    'Текст ссылок (междугородние обратные)',
                top_content: 'Текст после заголовка',
                city_from: 'Город отправления',
                city_from_id: 'Город отправления',
                'city_from.title': 'Город отправления',
                city_to: 'Город назначения',
                city_to_id: 'Город назначения',
                'city_to.title': 'Город назначения',
                template_from: 'Шаблон отправления',
                template_to: 'Шаблон назначения',
            },
            filters: {
                id: 'ИД',
                title: 'Наименование',
                active: 'Активна',
                slug: 'ЧПУ',
                city_id: 'Город',
                city_from: 'Город отправления',
                city_from_id: 'Город отправления',
                'city_from.title': 'Город отправления',
                city_to: 'Город назначения',
                city_to_id: 'Город назначения',
                'city_to.title': 'Город назначения',
                template_to: 'Шаблон отправления',
                template_from: 'Шаблон назначения',
            },
            fieldGroups: {
                seo: 'SEO',
                additional: 'Дополнительные настройки',
            },
            page: {},
            errors: {},
        },
        city_items_pages: {
            name: 'Страница НП |||| Страницы НП',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Заголовок',
                active: 'Активна',
                slug: 'ЧПУ',
                annotation: 'Аннотация',
                content: 'Контент',
                seo_h1: 'H1',
                seo_keywords: 'Keywords',
                seo_description: 'Описание',
                seo_no_index: 'NO_INDEX',
                seo_canonincal: 'Canonical',
                links_title_city_from: 'Текст ссылок (для города отправления)',
                links_title_city_to: 'Текст ссылок (для города назначения)',
                links_title_intercity_from:
                    'Текст ссылок (междугородние прямые)',
                links_title_intercity_to:
                    'Текст ссылок (междугородние обратные)',
                top_content: 'Текст после заголовка',
                city_item_from: 'НП отправления',
                city_item_from_id: 'НП отправления',
                'city_item_from.title': 'НП отправления',
                city_item_to: 'НП назначения',
                city_item_to_id: 'НП назначения',
                'city_item_to.title': 'НП назначения',
                template_from: 'Шаблон отправления',
                template_to: 'Шаблон назначения',
                last_call: 'Время последнего просмотра',
                call_counter: 'Количество просмотров',
            },
            filters: {
                id: 'ИД',
                title: 'Наименование',
                active: 'Активна',
                slug: 'ЧПУ',
                city_id: 'Город',
                city_from: 'Город отправления',
                city_from_id: 'Город отправления',
                'city_from.title': 'Город отправления',
                city_to: 'Город назначения',
                city_to_id: 'Город назначения',
                'city_to.title': 'Город назначения',
                template_to: 'Шаблон отправления',
                template_from: 'Шаблон назначения',
            },
            fieldGroups: {
                seo: 'SEO',
                additional: 'Дополнительные настройки',
            },
            page: {},
            errors: {},
        },
        delivery_pages: {
            name: 'Страница ТК |||| Страницы ТК',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Заголовок',
                active: 'Активна',
                slug: 'ЧПУ',
                annotation: 'Аннотация',
                content: 'Контент',
                seo_h1: 'H1',
                seo_keywords: 'Keywords',
                seo_description: 'Описание',
                seo_no_index: 'NO_INDEX',
                seo_canonincal: 'Canonical',
                links_title_city: 'Текст ссылок',
                city: 'Город',
                city_id: 'Город',
                'city.title': 'Город',
                template: 'Шаблон',
            },
            filters: {
                id: 'ИД',
                title: 'Наименование',
                active: 'Активна',
                slug: 'ЧПУ',
                city_id: 'Город',
                city_from: 'Город отправления',
                city_from_id: 'Город отправления',
                'city_from.title': 'Город отправления',
                city_to: 'Город назначения',
                city_to_id: 'Город назначения',
                'city_to.title': 'Город назначения',
                template_to: 'Шаблон отправления',
                template_from: 'Шаблон назначения',
            },
            fieldGroups: {
                seo: 'SEO',
                additional: 'Дополнительные настройки',
            },
            page: {},
            errors: {},
        },
        feedbacks: {
            name: 'Результат отправки формы |||| Результаты отправки формы',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Заголовок',
                content: 'Заголовок',
            },
            filters: {
                id: 'ИД',
                title: 'Наименование',
            },
            errors: {},
        },
        logix_users: {
            name: 'Пользователь |||| Пользователи',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                user_id: 'Logix User Id',
                access_client_id: 'Logix Access Client Id',
                user_hash: 'Авторизационный хеш',
                email: 'Email',
                phone: 'Телефон',
                disabled: 'Отключена',
                enabled: 'Включена',
            },
            filters: {
                id: 'ИД',
                user_id: 'Logix User Id',
                access_client_id: 'Logix Access Client Id',
                user_hash: 'Авторизационный хеш',
                email: 'Email',
                phone: 'Телефон',
            },
            errors: {},
        },
        logix_offices: {
            name: 'Офис |||| Офисы',
            fields: {
                has_office_category: 'Обслуживает клиентов (категория "Офис")',
            },
            filters: {},
            errors: {},
        },
        logix_storehouses: {
            name: 'Склад |||| Склады',
            fields: {},
            filters: {},
            errors: {},
        },
        logix_client_metas: {
            name: 'Метаданные контрагента |||| Метаданные контрагентов',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                full_name: 'Наименование',
                leader_name: 'ФИО начальника',
                leader_position: 'Должность начальника',
            },
            filters: {
                id: 'ИД',
                full_name: 'Наименование',
            },
            errors: {},
        },
        logix_cities: {
            name: 'Населённый пункт |||| Населённые пункты',
            fields: {
                id: 'ИД',
                geo_id: 'ИД',
                type: 'Тип',
                parent_id: 'Родительский элемент',
                title: 'Название',
                kind: 'Тип',
                code: 'Буквенный код',
                direction: 'Направление перевозок',
                timezone_offset: 'Часовой пояс',
                kladr: 'КЛАДР',
                region_id: 'ИД региона',
                district_id: 'ИД района',
                okrug_id: 'ИД округа',
                fias_id: 'ФИАС',
                active: 'Активен',
            },
            filters: {
                id: 'ИД',
                geo_id: 'ИД',
                title: 'Название',
                type: 'Тип',
                fias_id: 'ФИАС',
                active: 'Активен',
            },
            errors: {},
        },
        'blocks/packings': {
            name: 'Упаковка |||| Упаковка',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Наименование',
                price: 'Цена',
                minimum: 'Мин. цена',
                calc_note: 'Выводимое сообщение',
                countable: 'С выбором количества',
                countable_label: 'Текст выбора количества',
                multiplier_1: 'Коэффициент 1 (объёма)',
                multiplier_2: 'Коэффициент 2 (веса)',
                units_label: 'Единицы измерения',
                comment: 'Комментарий',
            },
            filters: {
                id: 'ИД',
                title: 'Наименование',
            },
            fieldGroups: {
                seo: 'SEO',
            },
            page: {
                delete: 'Удалить',
            },
            errors: {},
        },
        'blocks/advantages': {
            name: 'Преимущество |||| Преимущества',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Заголовок',
                content: 'Контент',
                active: 'Активен',
                element: 'Элемент',
                slug: 'ЧПУ (не для ручного ред-я)',
                link: 'Ссылка на элементе',
            },
            filters: {
                id: 'ИД',
                title: 'Заголовок',
                active: 'Активен',
                element: 'Элемент',
            },
            fieldGroups: {
                seo: 'SEO',
            },
            page: {
                delete: 'Удалить',
            },
            errors: {},
        },
        'blocks/slides': {
            name: 'Слайд |||| Слайды',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Заголовок',
                content: 'Контент',
                active: 'Активен',
                element: 'Элемент',
                slug: 'ЧПУ (не для ручного ред-я)',
                link: 'Ссылка на элементе',
            },
            filters: {
                id: 'ИД',
                title: 'Заголовок',
                active: 'Активен',
                element: 'Элемент',
            },
            fieldGroups: {},
            page: {
                delete: 'Удалить',
            },
            errors: {},
        },
        'blocks/faqs': {
            name: 'Вопросы и ответы',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                active: 'Активен',
                question: 'Вопрос',
                answer: 'Ответ',
            },
            filters: {
                id: 'ИД',
                active: 'Активен',
                question: 'Вопрос',
            },
            fieldGroups: {},
            page: {
                delete: 'Удалить',
            },
            errors: {},
        },
        'blocks/services': {
            name: 'Услуга |||| Услуги',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                title: 'Заголовок',
                active: 'Активна',
                slug: 'ЧПУ',
                annotation: 'Аннотация',
                content: 'Контент',
                seo_h1: 'H1',
                seo_keywords: 'Keywords',
                seo_description: 'Описание',
                seo_no_index: 'NO_INDEX',
                seo_canonincal: 'Canonical',
                city: 'Город',
                city_id: 'Город',
                'city.title': 'Город',
                is_all_cities: 'Все города',
                icon: 'Иконка',
            },
            filters: {
                id: 'ИД',
                title: 'Наименование',
            },
            fieldGroups: {
                seo: 'SEO',
            },
            page: {
                delete: 'Удалить',
            },
            errors: {},
        },
        tasks: {
            name: 'Задание |||| Задания',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                parsed_args: 'Параметры',
                args: 'Параметры',
                status: 'Статус',
                status_options: {
                    failed: 'Ошибка',
                    created: 'Создано',
                    in_progress: 'Выполняется',
                    executed: 'Завершено',
                },
                log: 'Лог',
                hook: 'Эндпойнт',
                author: 'Кем поставлено',
                planned_time: 'Запланировано на',
                execution_started_time: 'Старт выполнения',
                execution_ended_time: 'Время завершения',
            },
            filters: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                status: 'Статус',
                hook: 'Эндпойнт',
            },
            fieldGroups: {},
            page: {
                delete: 'Удалить',
            },
            errors: {},
        },
        actions: {
            name: 'Действие |||| Действия',
            fields: {},
            filters: {},
            fieldGroups: {
                logix: 'Logix',
                dynamic_pages: 'Динамические страницы',
                seo: 'SEO',
                common: 'Стандартные',
            },
            page: {
                logix_start_sync:
                    'Запустить синхронизацию справочников (склады, контакты (офисы))',
                logix_start_sync_geo: 'Запустить синхронизацию городов',
                logix_start_sync_geo_map:
                    'Запустить синхронизацию карты обслуживания городов',
                logix_start_sync_prices: 'Запустить синхронизацию цен',
                logix_start_sync_cargo_types:
                    'Запустить синхронизацию типов грузов',
                seo_sitemap_generation: 'Запустить генерацию Sitemap',
                clear_cache: 'Очистить кеш',
                dynamic_pages_cities: 'Регенерировать страницы доставки',
                dynamic_pages_delivery: 'Регенерировать страницы ТК',
                dynamic_pages_cities_items: 'Проверить страницы НП',
            },
            success: {
                redirect: 'Сейчас вы будете перенаправлены',
                tasks_created: 'Задания созданы',
            },
            errors: {
                common: 'Произошла ошибка',
            },
        },
        calculation_insurance_prices: {
            name: 'Цены страхования',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                limit: 'Лимит (до р. от заявленной стоимости)',
                price: 'Стоимость',
            },
            filters: {},
            fieldGroups: {},
            helpers: {
                insurance_overflow_percent:
                    'Для грузов стоимостью больше максимального лимита указывается процент, используемый в следующей формуле: (<заявленная_стоимость> + <стоимость_перевозки_межгорода>) * <значение_поля>%',
            },
            errors: {},
        },
        orders_filter_status_sets: {
            name: 'Настройка фильтра по статусу накладной в ЛК',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                label: 'Название',
                set: 'Статусы',
                front: 'Выводить на фронт',
            },
            filters: {},
            fieldGroups: {},
            helpers: {
                defaultValue: 'Значение-набор по умолчанию',
            },
            errors: {},
        },
        settings: {
            name: 'Настройки',
            fields: {
                id: 'ИД',
                namespace: 'Группа',
                path: 'Идентификатор',
                value: 'Значение',
                created: 'Создано',
                updated: 'Обновлено',
            },
            values: {
                logistics__base_domain: 'Основной домен сайта',
                logistics__body_scripts_insertion:
                    'Вставка скриптов после тега body',
                logistics__from: 'Отправитель email по умолчанию',
                logistics__from_name: 'Отправитель (текст) email по умолчанию',
                logistics__to: 'Получатель email по умолчанию',
                logistics__add_user_to:
                    'Получатель email о регистрации нового пользователя',
                logistics__to__helper: 'Можно указать несколько через запятую',
                logistics__default_to:
                    'Email для получения уведомлений по умолчанию',
                logistics__default_to__helper:
                    'Можно указать несколько через запятую',
                logistics__errors_to:
                    'Email для получения уведомлений об ошибках',
                logistics__errors_to__helper:
                    'Можно указать несколько через запятую. Значение добавляется к email для получения уведомлений по умолчанию.',
                logistics__blog_page_id: 'Корневая страница "Блог"',
                logistics__tracert_page_id: 'Страница отслеживания заявки',
                logistics__calculate_page_id: 'Страница полного калькулятора',
                logistics__vacancies_page_id: 'Корневая страница "Вакансии"',
                logistics__bids_page_id: 'Страница оформления заявки',
                logistics__header_phone: 'Телефон на страницах по умолчанию',
                logistics__marketing_search_header_phone:
                    'Телефон на страницах для поискового визита',
                logistics__og_image: 'Публичная ссылка на OG Image',
                logistics__calculation_insurance_prices: 'Тарифы страхования',
                logistics__map_inputs_enabled:
                    'Включить ввод адреса через карту',
                api_logix__orders_filter_status_sets:
                    'Настройка фильтра по статусу накладной в ЛК',
                api_logix__orders_filter_status_sets_default:
                    'Значение-набор по умолчанию',
                logistics__calculation_insurance_overflow_percent:
                    'Тарифы страхования: процент от стоимости при превышении лимита',
                cities__dadata_api_token: 'Токен для доступа к API Dadata',
                cities__results_limit:
                    'Количество результатов при запросе поиска в Dadata',
                bids__to: '[Заявки] Email для получения уведомлений',
                bids__to__helper: 'Можно указать несколько через запятую',
                bids__error_to:
                    '[Заявки] Email для получения уведомлений об ошибках',
                bids__error_to__helper: 'Можно указать несколько через запятую',
                logging_telegram__api_token: 'Api Token',
                logging_telegram__api_token__helper: 'https://t.me/BotFather',
                logging_telegram__chat_id: 'ID чата',
                logging_telegram__chat_id__helper:
                    'https://api.telegram.org/bot<YourBOTToken>/getUpdates result.message.migrate_to_chat_id',
                logging_telegram__calculator_zero_value: 'Расчет: нулевая стоимость',
                logging_telegram__calculator_no_transport: 'Расчет: отсутствует перевозка',
                logging_telegram__calculator_empty_response: 'Расчет: нет ответа',
                logging_telegram__calculator_invalid_response: 'Расчет: невалидный ответ',
                logging_telegram__calculator_no_arrival_plan: 'Расчет: без доставки',
                logging_telegram__order_zero_value: 'Заявка: заявка не найдена',
                logging_telegram__order_invalid_response: 'Заявка: невалидный ответ',
                logging_telegram__events: 'Запросы',
                orders_pdf__title: 'Заголовок',
                orders_pdf__subtitle: 'Подзаголовок',
                api_logix__server: 'Эндпойнт для запросов',
                api_logix__debug_front:
                    'Выводить блоки отладки в формах (калькулятор и заявка)',
                api_logix__debug_storehouses:
                    'Выводить доступные склады безотносительно их количества',
                api_logix__force_actions:
                    'Синхронизировать данные (цены и т.д.) без учёта различий в данных',
                api_logix__sync_user_id: 'ИД',
                api_logix__sync_user_hash: 'Авторизационная хеш-строка',
                api_logix__prices_derivation_percent:
                    'Процент допустимого различия цен при синхронизации',
                api_logix__calculator_no_response_error:
                    'Сообщение при возникновении ошибки при запросе расчёта',
                api_logix__order_no_response_error:
                    'Сообщение при возникновении ошибки при отправке заявки',
                api_logix__order_form_message_autoswitch_to_delivery:
                    '[Заявка] Сообщение при автоматическом переключении на доставку при отсутствии терминала в НП',
                errormessage__calculatenoresult:
                    'Сообщение при возникновении ошибки при запросе расчёта',
                messagetext__registrationmessage:
                    'Сообщение при успешной регистрации',
                messagetext__physicalregistrationmessage:
                    'Сообщение при успешной регистрации',
                messagetext__afterregistrationmessage:
                    'Сообщение при успешной регистрации',
                messagetext__successformtext:
                    'Сообщение по умолчанию при успешной отправке формы',
                adsvisit__criteria: '[AdsVisit] Критерий',
                city_items_pages__from_pages_active:
                    'Генерировать страницы from',
                city_pages__before_city_additional_list: 'Текст перед списком',
                city_pages__after_city_additional_list: 'Текст после списка',
                transport_company_pages__before_city_additional_list:
                    'Текст перед списком',
                transport_company_pages__after_city_additional_list:
                    'Текст после списка',
                shortcodes__oversized_conditions_title: 'Заголовок',
                shortcodes__oversized_conditions_text: 'Текст',
                city_pages__before_to_city_table:
                    'Текст перед таблицей городских тарифов города получения',
                city_pages__after_to_city_table:
                    'Текст после таблицы городских тарифов города получения',
                city_pages__before_from_city_table:
                    'Текст перед таблицей городских тарифов города отправления',
                city_pages__after_from_city_table:
                    'Текст после таблицы городских тарифов города отправления',
                city_pages__before_intercity_table:
                    'Текст перед таблицей междугородних тарифов',
                city_pages__after_intercity_table:
                    'Текст после таблицы междугородних тарифов',
                city_pages__update_city_pages_from_city_active:
                    'Страницы только с городом-отправителем активны',
                reviews__from: '[Отзыв] Отправитель email по умолчанию',
                reviews__from_name:
                    '[Отзыв] Отправитель (текст) email по умолчанию',
                reviews__to: '[Отзыв] Email для получения уведомлений',
                reviews__to__helper: 'Можно указать несколько через запятую',
                feedback__from:
                    '[Обратная связь] Отправитель email по умолчанию',
                feedback__from_name:
                    '[Обратная связь] Отправитель (текст) email по умолчанию',
                feedback__to:
                    '[Обратная связь] Email для получения уведомлений',
                feedback__to__helper: 'Можно указать несколько через запятую',
                feedback__quiz_to_emails:
                    '[Форма опроса] Email для получения уведомлений',
                feedback__quiz_to_emails__helper:
                    'Можно указать несколько через запятую',
                feedback__yandexSmartCaptcha__header: 'Yandex SmartCaptcha',
                feedback__yandexSmartCaptcha_sitekey: 'Ключ клиента',
                feedback__yandexSmartCaptcha_secret: 'Ключ сервера',
                site__vk: 'VK',
                site__facebook: 'Facebook',
                site__instagram: 'Instagram',
                site__telegram: 'Telegram',
                input_helpers__order_cargo_type_text: 'Поле "Характер груза"',
                input_helpers__order_client_order_num_text:
                    'Поле "Клиентский номер заказа"',
                input_helpers__order_packings_text: 'Поле "Упаковка"',
                input_helpers__order_additional_services_sender_text:
                    'Заголовок "Дополнительные услуги" (отправитель)',
                input_helpers__order_additional_services_receiver_text:
                    'Заголовок "Дополнительные услуги" (получатель)',
            },
            filters: {
                id: 'ИД',
                namespace: 'Группа',
                path: 'Идентификатор',
                value: 'Значение',
                created: 'Создано',
                updated: 'Обновлено',
            },
            fieldGroups: {
                general: 'Общие настройки',
                general_basic: 'Основные',
                general_forms: 'Формы',
                general_logging: 'Логирование',
                general_logging__telegram: 'Telegram',
                general_logging__telegram_topics: 'ИД топиков',
                general_logging__telegram_message_thread_helper: 'Чтобы узнать message_thread_id, необходимо в топике сделать правый клик на сообщении, выбрать "Copy Message Link". Формат полученной ссылки: "https://t.me/c/<group_id_short>/<message_thread_id>/<message_id>".',
                general_orders: 'Накладные',
                general_orders__pdf: 'Генерация PDF',
                logix: 'Настройка API Logix: клиент',
                logix_basic: 'Основное',
                logix_sync_user: 'Аккаунт для автоматической синхронизации',
                logix_additional: 'Дополнительные настройки',
                logix_frontend: 'Фронтенд',
                calculator: 'Калькулятор и заявка',
                helpers: 'Подсказки полей',
                helpers__order: 'Форма оформления заявки',
                seo: 'SEO',
                seo__socials: 'Соц. ссылка в подвале',
                shortcodes: 'Шорткоды',
                shortcode__tariffs_form: 'tariffs_form',
                shortcode__city_additional_list: 'city_additional_list',
                shortcode__tk_additional_list: 'tk_additional_list',
                shortcode__oversized_conditions: 'oversized_conditions',
                cities: 'Настройки НП',
                dynamic_pages: 'Динамические страницы',
                city_pages: 'Страницы доставки',
                city_items_pages: 'Страницы НП',
                page_replacement: 'Подмена контента страниц',
            },
            messages: {
                indexStub: 'Выберите необходимый раздел из бокового меню',
            },
            general: 'Общие настройки',
            logix: 'Logix',
            calculator: 'Калькулятор и заявка',
            helpers: 'Подсказки полей',
            seo: 'SEO',
            shortcodes: 'Шорткоды',
            cities: 'Настройки НП',
            dynamic_pages: 'Динамические страницы',
        },
        robots: {
            name: 'Robots',
            fields: {
                id: 'ИД',
                created: 'Создано',
                modified: 'Обновлено',
                city_title: 'Город',
                body: 'Содержимое',
                city: 'Город',
                city_id: 'Город',
                'city.title': 'Город',
                'city_entity.title': 'Город',
            },
            filters: {
                id: 'ИД',
                city_title: 'Город',
                city_id: 'Город',
                body: 'Содержимое',
            },
            page: {
                delete: 'Удалить',
            },
            errors: {},
        },
    },
};

export default customRussianMessages;
