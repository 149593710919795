import * as React from 'react';
import {
    Edit,
    useTranslate,
    SimpleForm,
    ImageInput,
    ImageField,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import SegmentsFormToolbar from './SegmentsFormToolbar';
import { RichTextInput } from '../components/RichTextInput';

const HelperForm = () => {
    const translate = useTranslate();
    const formDefaults = {
        multiple: true,
        method: 'post',
    };

    return (
        <Edit resource="settings" redirect={false} mutationMode="pessimistic">
            <SimpleForm
                defaultValues={formDefaults}
                toolbar={<SegmentsFormToolbar />}
            >
                <Grid container width={{ xs: '100%', xl: '80%' }} spacing={2}>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        minWidth={{ xs: '100%', xl: '80%' }}
                    >
                        <Typography variant="h3" gutterBottom>
                            {translate(
                                'resources.settings.fieldGroups.helpers'
                            )}
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                            {translate(
                                'resources.settings.fieldGroups.helpers__order'
                            )}
                        </Typography>
                        <RichTextInput
                            source="InputHelpers__order_cargo_type_text"
                            label="resources.settings.values.input_helpers__order_cargo_type_text"
                            fullWidth
                        />
                        <RichTextInput
                            source="InputHelpers__order_client_order_num_text"
                            label="resources.settings.values.input_helpers__order_client_order_num_text"
                            fullWidth
                        />
                        <RichTextInput
                            source="InputHelpers__order_packings_text"
                            label="resources.settings.values.input_helpers__order_packings_text"
                            fullWidth
                        />
                        <RichTextInput
                            source="InputHelpers__order_additional_services_sender_text"
                            label="resources.settings.values.input_helpers__order_additional_services_sender_text"
                            fullWidth
                        />
                        <RichTextInput
                            source="InputHelpers__order_additional_services_receiver_text"
                            label="resources.settings.values.input_helpers__order_additional_services_receiver_text"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};

export default HelperForm;
